import React from "react";
import {Container} from "@mui/material";

const BlankPage = ({children}) => {
  return (
    <Container
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {children}
    </Container>
  );
};

export default BlankPage;
