import React from "react";
import {Box, Button} from "@mui/material";

const FeatureButton = ({section, src, text}) => {
  return (
    <Button
      variant={"outlined"}
      sx={{
        textTransform: "capitalize",
        flexDirection: "column",
        padding: 2,
        width: "100%"
      }}
      href={section}
    >
      <Box
        component={"img"}
        src={src}
        alt={text}
        width={100}
        height={100}
      />
      {text}
    </Button>
  );
};

export default FeatureButton;
