import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import BlankPage from "../components/BlankPage";
import {notFoundGif} from "../utils/importStaticFromDrive";
import {Box, Typography} from "@mui/material";


const NotFound = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //TODO: Add gif
  return (
    <>
      <Helmet>
        <title>{t("helmet.notFound")}</title>
      </Helmet>
      <BlankPage>
        <Typography fontWeight={'bold'} variant={'h1'}>{t("notFound.title")}</Typography>
        <Box
          component={"img"}
          src={notFoundGif}
          alt={"notfound"}
          width={"100%"}
          maxWidth={500}
          borderRadius={500}
        />
      </BlankPage>
    </>
  );
};

export default NotFound;
