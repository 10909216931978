import React from "react";
import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GENERAL, LINKS } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { logo} from "../../utils/importStaticFromDrive";
import {Apple, Google} from "@mui/icons-material";

const TitleAndStoreLinks = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { width } = useWindowDimensions();
  const mobile = width < GENERAL.MAX_MOBILE_WIDTH;

  //TODO: change links
  return (
    <Grid
      item
      container
      md={6}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Paper
        elevation={6}
        sx={{
          width: "100%",
          height: "100%",
          padding: 2,
          backgroundColor: palette.primary.main,
          color: "white"
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            container
            item
            xs={12}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Grid item xs={12}>
              <Box
                component={"img"}
                src={logo}
                alt={"logo"}
                width={150}
                borderRadius={5}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={mobile ? "h4" : "h2"} sx={{fontWeight: 'bold'}}>
                {t("sections.header.title")}
              </Typography>
              <Typography variant={mobile ? "h6" : "h4"}>
                {t("sections.header.subTitle")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={mobile ? "body1" : "h6"}>
                {t("sections.header.slogan")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            py={3}
          >
            <Grid item md={6}>
              <Button
                variant={"contained"}
                color={"black"}
                startIcon={<Apple />}
                sx={{textTransform: 'capitalize'}}
                onClick={() => {
                  window.open(LINKS.APP_STORE);
                }}
              >
                App Store
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                variant={"contained"}
                color={"black"}
                startIcon={<Google />}
                sx={{textTransform: 'capitalize'}}
                onClick={() => {
                  window.open(LINKS.GOOGLE_PLAY);
                }}>
                Google Play
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TitleAndStoreLinks;
