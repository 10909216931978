import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import TopNav from "./components/TopNav";
import { Route, Routes } from "react-router";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import { ROUTES, THEME } from "./utils/constants";
import { useTranslation } from "react-i18next";
import TermsOfUse from "./screens/TermsOfUse";
import Eula from "./screens/Eula";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Admin from "./screens/Admin";
import './App.css'

function App() {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Helmet>
        <title>{t("helmet.home")}</title>
      </Helmet>
      <TopNav />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />}></Route>
        <Route path={ROUTES.ADMIN} element={<Admin />}></Route>
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
        <Route path={ROUTES.APP_TERMS_OF_USE} element={<TermsOfUse />} />
        <Route
          path={ROUTES.APP_END_USER_LICENSE_AGREEMENT}
          element={<Eula />}
        />
        <Route path={ROUTES.APP_PRIVACY_POLICY} element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
