import React from "react";
import BlankPage from "../BlankPage";
import { useTranslation } from "react-i18next";
import Section from "../common/Section";
import { SECTIONS } from "../../utils/constants";
import {
  devicesGif, infoGif,
  polaroidGif,
  pregnancyHome, pregnancyInfo, pregnancyPhotoAlbum,
  pregnancyProfiles, pregnancyToolbox,
  profilesGif, settingsLanguages, toolboxGif, translateGif
} from "../../utils/importStaticFromDrive";

const Sections = () => {
  const { t } = useTranslation();
  return (
    <BlankPage>
      <Section
        id={SECTIONS.UNLIMITED}
        gif={devicesGif}
        image={pregnancyHome}
        title={t("sections.multipleDevices.title")}
        text={t("sections.multipleDevices.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.SHARE}
        gif={profilesGif}
        image={pregnancyProfiles}
        title={t("sections.profiles.title")}
        text={t("sections.profiles.text")}
      />
      <Section
        id={SECTIONS.ITEMS}
        gif={infoGif}
        image={pregnancyInfo}
        title={t("sections.info.title")}
        text={t("sections.info.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.EXPORT}
        gif={polaroidGif}
        image={pregnancyPhotoAlbum}
        title={t("sections.photoAlbum.title")}
        text={t("sections.photoAlbum.text")}
      />
      <Section
        id={SECTIONS.DEVICES}
        gif={toolboxGif}
        image={pregnancyToolbox}
        title={t("sections.toolbox.title")}
        text={t("sections.toolbox.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.LANGUAGES}
        gif={translateGif}
        image={settingsLanguages}
        title={t("sections.languages.title")}
        text={t("sections.languages.text")}
      />
    </BlankPage>
  );
};

export default Sections;
