const urlFromImageId = (id) =>
  `https://drive.google.com/thumbnail?id=${id}&sz=w800`;

export const logo = urlFromImageId("1OAok8qM6yz5CYehJCJQF5kdG9_Vpazc9");
export const adaptiveIcon = urlFromImageId("1y8CSGOonqTyA3yM1LeYcPqqf-tyQT0-p");

export const polaroidGif = urlFromImageId("1GovTfohi-QMzn2Di7-u2T3VUdczDPydv");
export const translateGif = urlFromImageId("1muOma2xlm9_jL-i5uJgxQWjVq25hTeiz");
export const infoGif = urlFromImageId("147KzrYxIdZ5pzqM9EH4HOco35Z7AGYQB");
export const devicesGif = urlFromImageId("1BBlO3-awjysIUUVgFIsVbYsc2rs2pIWH");
export const profilesGif = urlFromImageId("1RmvfDxLCrjZS5AR-spjJGgsoyhb-o3gT");
export const toolboxGif = urlFromImageId("1O6JlDZzIZz_Kr6WQLMWsbkfxMj3e9n3y");
export const notFoundGif = urlFromImageId("1npQLiFYGUtG2_Nlc3OcSsvyF8WWP_Jry");

export const pregnancyProfiles = urlFromImageId("1Gl0kHHmA0AO_-OPlMiyJhDMK2NVooMbF");
export const pregnancyToolbox = urlFromImageId("1gnAci_xwPoq82hvKVMSd29Ix9QzBomAW");
export const pregnancyPhotoAlbum = urlFromImageId("1fWZlPcAkBqmHd37-96FmPCrSi6IXoxuh");
export const settingsLanguages = urlFromImageId("1oJq47A93yfroLTYx5JXJH5-RDDMGXlsf");
export const pregnancyInfo = urlFromImageId("1Zgjnhysyg4-xpOrBGpItD5rLRhWpJadS");
export const pregnancyHome = urlFromImageId("1qJXWLGOm9EY1FIV9-3NrbV179eGflrMh");



