import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SECTIONS } from "../../utils/constants";
import FeatureButton from "../common/FeatureButton";
import {
  devicesGif,
  infoGif,
  polaroidGif,
  profilesGif,
  toolboxGif,
  translateGif
} from "../../utils/importStaticFromDrive";

const FeaturesList = () => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      md={6}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Grid item xs={6}>
        <FeatureButton
          src={devicesGif}
          text={t("sections.multipleDevices.title")}
          section={`#${SECTIONS.UNLIMITED}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={profilesGif}
          text={t("sections.profiles.title")}
          section={`#${SECTIONS.SHARE}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={infoGif}
          text={t("sections.info.title")}
          section={`#${SECTIONS.ITEMS}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={polaroidGif}
          text={t("sections.photoAlbum.title")}
          section={`#${SECTIONS.EXPORT}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={toolboxGif}
          text={t("sections.toolbox.title")}
          section={`#${SECTIONS.DEVICES}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FeatureButton
          src={translateGif}
          text={t("sections.languages.title")}
          section={`#${SECTIONS.LANGUAGES}`}
        />
      </Grid>
    </Grid>
  );
};

export default FeaturesList;
